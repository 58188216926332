export default {
  // module
  "module.provider-register": "供應商註冊",
  "module.provider-info": "供應商基本資料",

  // data
  "provider.data.name": "供應商名稱",
  "provider.data.member_name": "管理者姓名",
  "provider.data.phone": "聯絡電話",
  "provider.data.bank_account_name": "帳戶名",
  "provider.data.bank_account": "銀行帳號",
  "provider.data.bank_info": "銀行資訊",
  "provider.data.bank_branch_id": "銀行分行代碼",
  "provider.data.comment": "備註",
  "provider.data.city": "出貨縣市",
  "provider.data.district": "出貨地區",
  // form

  // others
  "provider.error.register": "帳號已註冊過供應商",
  "provider.status.request": "新申請",
  "provider.status.cooperation": "已合作",
  "provider.status.reject": "已拒絕",
  "provider.status.detail.request": "申請中",
  "provider.status.detail.cooperation": "合作中",
  "provider.status.detail.reject": "拒絕合作",
  "provider.co-maintainer": "供應商共同管理員",

  // 客戶管理
  "client-info": "客戶管理",
  "client.search_keyword": "輸入客戶名稱、聯絡人、聯絡電話搜尋",

  // 請款
  "apply-pay.status.pending": "尚未付款",
  "apply-pay.status.success": "付款完成",
  "apply-pay.status.cancel": "已取消",

  // 揀貨單
  // 'action.picking_list.export': '匯出揀貨總表',
  "action.picking_list.export": "匯出訂單",
  "action.picking_list.export.order": "匯出揀貨單",
  "action.picking_list.export.summary": "匯出訂單彙總",
  "action.picking_list.export.abstract": "匯出訂單摘要",

  "export_excel.error.picking_list.no_dates": "匯出揀貨總表需要選擇時間區間",
  "export_excel.error.picking_list.no_dates2": "匯出訂單彙總需要選擇時間區間",
  "export_excel.error.picking_list.no_dates3": "匯出訂單摘要需要選擇時間區間",
  "export_excel.error.picking_list.apply_only": "揀貨單僅匯出尚未出貨的訂單",
  "export_excel.error.picking_list.no_selection": "請勾選要匯出揀貨單的訂單",
  "export_excel.filename.picking_list": "揀貨總表",
  "export_excel.filename.picking_list.order": "揀貨單",
};
